<template>
  <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">
                {{ course.number + ' - ' + course.name }} : إضافة بروموكود
              </h6>
            </div>
          </div>
          <div class="card-body px-4 pb-2">
            <form @submit="createPromoCode" role="form" class="text-end mt-3">
              <div class="row">
                <div class="mb-3 col-3">
                  <label>
                    عدد البروموكود
                    <span class="badge bg-info">{{ codes.length }}</span>
                    <!-- <span color="danger" class="error">{{
                      errors.count ? errors.count[0] : ''
                    }}</span> -->
                  </label>
                </div>
                <div class="mb-3 col-5">
                  <vmd-input
                    id="promo-code-number"
                    type="number"
                    label="عدد الرموز الجديدة لإضافتها"
                    v-model="course.promo_code_number"
                    required
                  />
                </div>
                <div class="mb-3 col-4 text-end">
                  <button
                    class="btn btn-info mb-2"
                    type="button"
                    variant="gradient"
                    color="info"
                    @click="createCode"
                  >
                    اضافة
                  </button>
                </div>
              </div>
              <div class="row">
                <div class=" col-lg-5 alert-div">
                  <span class=" alert alert-light sm m-0 " role="alert">
                     عند اضافة كود جديد الرجاء الضغط على حفظ
                  </span>
                </div>
                  <div class="text-center col-lg-2">
                  <vmd-button class="my-4 mb-2 width" variant="gradient" color="info">
                    حفظ</vmd-button
                  >
                </div>
                <div class=" col-lg-2 ">
                  <vmd-button
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="secondary"
                    @click="downloadFile($event)"
                    >  ملف اكسل </vmd-button
                  >
                  </div>
                   <div class="text-center col-lg-2">
                  <vmd-button
                   @click='goBack($event)'
                    class="my-4 mb-2 width"
                    variant="gradient"
                    color="warning"
                    > رجوع</vmd-button
                  >
                </div>
              </div>
              <div class="row mt-3">
                <div class="mb-3 col-lg-12">
                  <div class="table-responsive">
                    <table class="table table-stripped align-items-center mb-0">
                      <thead>
                        <tr>
                          <th>الكود</th>
                          <th>الطالب المستخدم للكود</th>
                          <th>تاريخ الانشاء</th>
                          <th>تاريخ الإستحدام</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in codes" :key="index">
                          <td v-if="row.created_at">{{ row.value }}</td>
                          <td v-else>
                            <div
                              class="input-group input-group-outline null is-filled"
                            >
                              <input
                                id="promo-code-number"
                                type="text"
                                v-model="row.value"
                                class="form-control form-control-default"
                                size="6"
                                maxlength="6"
                                disabled
                                style="text-transform: uppercase"
                                required
                              />
                            </div>
                          </td>
                          <td>{{ row.student?row.student.full_name:'' }}</td>
                          <td>{{ row.created_at }}</td>
                          <td>{{ row.student_courses?row.student_courses.used_at:'' }}</td>
                          <td>
                          <vmd-button
                          v-if="row.student==='جديد'"
                             @click='removeCode(index,$event)'
                            class="my-4 mb-2"
                             variant="gradient"
                            color="danger"
                            >
                           حذف</vmd-button
                          >
                        </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import exportFromJSON from 'export-from-json'
import VmdInput from '@/components/VmdInput.vue'
import $ from 'jquery'
const date = new Date()
const today =
  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
export default {
  name: 'CreatePromoCode',
  components: {
    VmdButton,
    VmdInput
  },
  data () {
    return {
      course: {},
      codes: [],
      codesFile: [],
      errors: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}courses/${this.$route.params.id}`)
      .then((response) => {
        this.course = response.data.data
      })
    this.$http
      .get(`${this.$hostUrl}promo-codes?course_id=${this.$route.params.id}`)
      .then((response) => {
        this.codes = response.data.data
      })
  },
  methods: {
    goBack (e) {
      e.preventDefault()
      this.$router.push({ path: '/courses' })
    },
    downloadFile (e) {
      e.preventDefault()
      $.each(this.codes, (key, val) => {
        this.codesFile.push({
          الكود: val.value,
          الطالب_المستخدم_للكود: val.student ? val.student.full_name : '-',
          تاريخ_الإنشاء: val.created_at,
          تاريخ_الإستخدام: val.student_courses ? val.student_courses.used_at : '-'

        })
      })
      const data = this.codesFile
      const fileName = ` برومو كود - ${today}`
      const exportType = exportFromJSON.types.xls

      if (data) exportFromJSON({ data, fileName, exportType })
    },
    createCode () {
      const characters = '0123456789'
      let result = ''
      for (let i = 0; i < this.course.promo_code_number; i++) {
        for (let i = 0; i < 8; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * characters.length)
          )
        }
        this.codes.unshift({
          value: result,
          student: 'جديد',
          created_at: ''
        })
        result = ''
      }
    },
    removeCode (index, e) {
      e.preventDefault()
      this.codes.splice(index, 1)
      this.course.promo_code_number--
    },
    async createPromoCode (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('course_id', this.$route.params.id)
      let count = 0
      this.codes.forEach(function (code) {
        if (!code.created_at) {
          count += 1
          formData.append('values[]', code.value)
        }
      })
      formData.append('count', count)
      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'promo-codes',
        ''
      )
      this.$http
        .get(`${this.$hostUrl}promo-codes?course_id=${this.$route.params.id}`)
        .then((response) => {
          this.codes = response.data.data
        })
      this.course.promo_code_number = ''
    }
  }
}
</script>
<style >
  .alert-warning{
    color: #fff !important;
    padding: 5px 20px !important;
    border-radius: 20px !important;
  }
  .alert-div{
     margin: 33px 4px 18px !important;
  }
</style>
